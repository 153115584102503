var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"d-flex pa-0 align-center"},[_c('h1',[_vm._v("Sessions")]),_c('v-spacer'),_c('v-spacer')],1),_c('v-card',{staticClass:"mt-2"},[_c('v-app-bar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title')],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getListSessions,"data-test":"dataTable-field","item-key":"uid","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberSessions,"options":_vm.pagination,"disable-sort":true},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" check_circle ")])]}}],null,true)},[_c('span',[_vm._v("active "+_vm._s(_vm._f("lastSeen")(item.last_seen)))])])]}},{key:"item.device",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.device.uid } }}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [(!item.authenticated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.username))])]}}],null,true)},[(!item.authenticated)?_c('span',[_vm._v("Unauthorized")]):_vm._e()]):_vm._e(),(item.authenticated)?[_vm._v(" "+_vm._s(item.username)+" ")]:_vm._e()]}},{key:"item.authenticated",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.authenticated)?_c('v-icon',_vm._g({attrs:{"color":item.active ? 'success' : '',"size":""}},on),[_vm._v(" mdi-shield-check ")]):_c('v-icon',_vm._g({attrs:{"color":"error","size":""}},on),[_vm._v(" mdi-shield-alert ")])]}}],null,true)},[(item.authenticated)?_c('span',[_vm._v("User has been authenticated")]):_c('span',[_vm._v("User has not been authenticated")])])]}},{key:"item.ip_address",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.ip_address))])]}},{key:"item.started",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.started_at))+" ")]}},{key:"item.last_seen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_seen))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"icons",on:{"click":function($event){return _vm.detailsSession(item)}}},on),[_vm._v(" info ")])]}}],null,true)},[_c('span',[_vm._v("Details")])]),(item.active)?_c('SessionClose',{attrs:{"uid":item.uid,"device":item.device_uid},on:{"update":_vm.refresh}}):_vm._e(),_c('SessionPlay',{attrs:{"recorded":item.authenticated && item.recorded,"uid":item.uid}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }