<template>
  <div>
    <ListSession />
  </div>
</template>

<script>

import ListSession from '@/components/session/SessionList';

export default {
  name: 'Sessions',

  components: {
    ListSession,
  },
};

</script>
